* {
  box-sizing: border-box;
}

body {
  background-color: #f5f5f5;
}

h1 {
  text-align: center;
}

.container-fluid {
  padding: 0;
}

.header {
  max-width: 100vw;
  height: auto;
}

.header-img {
  width: 100%;
}

.nav-container {
  margin: 0;
  max-width: 100vw;
}

.navbar {
  background-color: #333333;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-collapse {
  display: flex;
  justify-content: space-between;
}

.navbar-nav {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.carousel-item {
  height: 200px;
  overflow: hidden;
  position: relative;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-content {
  padding: 1rem;
  max-width: 100vw;
}

.hp-nonprofit-text {
  margin-top: 1rem;
}

.img-text-container {
  width: 100%;
}

.footer {
  color: white;
  background-color: #333333;
  padding: 0.5rem;
  margin-top: 2rem;
}

.footer-content {
  font-size: clamp(0.8rem, 2vw, 1rem);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.wwd-img-1, .wwd-img-2 {
  width: 100%;
  min-width: 225px;
}

.wwd-img-1 {
  margin-top: 1rem;
}

.wwd-img-2 {
  margin: 1rem 0;
}

.img-wrapper {
  display: flex;
  justify-content: center;
}

.videos-photos h1 {
  text-align: center;
}

.videos-photos img, .mission img, .wwd img {
  border-radius: 5px;
}

.video-photo-wrapper {
  max-width: 100vw;
}

.vp-img {
  margin: auto;
  margin-bottom: 1rem;
  max-width: 1100px;
}

.vp-img:last-of-type {
  margin-bottom: 0;
}

.small-text {
  font-size: 0.8rem;
}

.pi-subheading, .enlarge-text {
  text-align: center;
}

.mission-img-1, .mission-img-2 {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.mission-img-1 {
  margin-top: 1rem;
}

/* Styles for the photo previews */

.collection-link-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 2rem;
  row-gap: 3rem;
  align-content: center;
}

.collection-link-container a {
  text-decoration: none;
}

.photo-previews-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  position: relative;
}

.photo-preview {
  width: 200px;
  height: 200px;
  overflow: hidden;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
}

.photo-preview img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  object-position: center center;
  position: relative;
  z-index: 1;
}

.photo-preview-2 {
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 0;
  transform: rotate(5deg);
  max-width: 100%;
  max-height: 100%;
}

.title {
  margin-top: 10px;
}

/* Styles for the photo grid */

.photo-grid-item {
  margin-bottom: 1rem;
}

.photo-grid-item img,  .photo-grid-item video {
  cursor: zoom-in;
  position: relative;
}

.photo-grid-item img, .photo-grid-item video {
  max-width: 100%;
  border-radius: 5px;
  height: auto;
  display: block;
}

.photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.photo-overlay:hover {
  opacity: 1;
}

.photo-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.photo-description {
  font-size: 14px;
}

/* Lightbox Styles */

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.lightbox img, .lightbox video {
  max-width: 90%;
  max-height: 90%;
}

.prev, .next {
  font-size: 40px;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.progress-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.progress-dot {
  width: 10px;
  height: 10px;
  background-color: #aaa;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.progress-dot.active {
  background-color: #fff;
}

@media (min-width: 545px) {
  .carousel-item {
    height: 400px;
  }

  .page-content {
    padding: 2rem;
  }

  .wwd-img-1 {
    display: block;
    margin: 1rem 0 1rem 1rem;
    max-width: 75vw;
    width: 60vw;
    max-width: 750px;
    float: right;
  }

  .wwd-img-2 {
    display: block;
    margin: 1rem auto;
    max-width: 75vw;
    width: 20vw;
    height: auto;
    float: left;
    margin: 0 1rem 0 0;
  }

  .mission-img-1 {
    width: 16rem;
    height: auto;
    float: left;
    margin: 1rem 1rem 1rem 0;
  }
  
  .mission-img-2 {
    width: 16rem;
    height: auto;
    float: right;
    margin: 0 0rem 1rem 1rem;
  }

  .footer-content {
    padding: 1rem;
  }

  .photo-grid-item {
    margin: 0;
  }

  .photo-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

}

@media (min-width: 768px) {
  .wwd-img-2 {
    width: 3%;
    height: auto;
    margin-bottom: 1rem;
  }

  .carousel-item {
    height: 560px;
  }

  .carousel-item img:nth-of-type(5) {
    object-fit: cover;
  }

  h1, .pi-subheading, .enlarge-text {
    text-align: left;
  }

  .photo-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}
